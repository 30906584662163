import React, { useState } from "react";
import "./tariffs.css";
import { useTranslation } from "react-i18next";
import main_data from "../../data";
import { FaCrown } from "react-icons/fa";

const Tariffs = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("websites");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const filteredPlans = main_data.filter((el) => el.type === activeTab);

  console.log(filteredPlans.data);

  return (
    <div id="tariffs" className="tariffs">
      <div className="container">
        {t("leng") === "en" ? (
          <h2>
            Our <span>tariffs</span>{" "}
          </h2>
        ) : (
          <h2>
            наши <span>тарифы</span>{" "}
          </h2>
        )}
      </div>
      <div className="tabs">
        <div className="tabo container">
          <div className="btns_tabs">
            <button
              className={`btn_tab ${
                activeTab === "websites" ? "active" : ""
              } first`}
              onClick={() => handleTabClick("websites")}
            >
              Сайты
            </button>
            <button
              className={`btn_tab ${
                activeTab === "mobile_apps" ? "active" : ""
              }`}
              onClick={() => handleTabClick("mobile_apps")}
            >
              Мобильные Приложения
            </button>
            <button
              className={`btn_tab ${activeTab === "crm" ? "active" : ""}`}
              onClick={() => handleTabClick("crm")}
            >
              CRM-системы и автоматизация
            </button>
            <button
              className={`btn_tab ${
                activeTab === "it_platforms" ? "active" : ""
              } last`}
              onClick={() => handleTabClick("it_platforms")}
            >
              IT-Платформы
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="scroll_wrapper">
          <div className="wrapper">
            {filteredPlans[0]?.data?.map((el, id) => (
              <div key={id} className={`tariffs_box ${el?.bool && "active"}`}>
                <div>
                  <div className="con_tariffs_box">
                    <h4 className="title">
                      {el.title} {el.bool && <FaCrown color="gold" />}
                    </h4>
                    <p className="desc">{el.description}</p>
                  </div>
                  <div className="points">
                    {el.points.map((item, index) => (
                      <div key={index} className="point_box">
                        {item.icon}
                        <p className="point">{item.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="con_tariffs_box">
                  <div className="blocks">
                    <p className="timeline">{el.timeline}</p>
                    <p className="price">{el.price}</p>
                  </div>
                  <button
                    onClick={() => {
                      const text = `Здравствуйте! Я хочу выбрать тариф: ${el.title}.`;
                      const phoneNumber = "996990260795";
                      const encodedText = encodeURIComponent(text);
                      window.open(
                        `https://wa.me/${phoneNumber}?text=${encodedText}`,
                        "_blank"
                      );
                    }}
                    className="btngo"
                  >
                    Выбрать тариф
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tariffs;
