import React from "react";
import "./first_block.css";
import arrow from "../../assets/images/arrow (7).svg";
import one from "../../assets/images/metTwo.png";
import two from "../../assets/images/metOne.png";
import three from "../../assets/images/metThree.png";
import elips from "../../assets/images/Ellipse.png";
import { useTranslation } from "react-i18next";

const FirstBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="first_block">
      <div className="container">
        <div className="blockx">
          {t("leng") === "en" ? (
            <h1>
              your IT
              <br />
              product <br />
              <span>in a short</span> <br /> time
            </h1>
          ) : (
            <h1>
              ваш it-
              <br />
              продукт <br />
              <span>в короткие</span> <br /> сроки
            </h1>
          )}
          <p className="text absolute1">{t("first_text")}</p>
          <div className="absolute2">
            <p className="text">{t("secend_text")}</p>
            <a
              className="green_btn_a"
              href="http://t.me/+996990260795"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btn">
                <p>{t("discuss")}</p>
                <img loading="lazy" className="arrow" src={arrow} alt="" />
              </div>
            </a>
          </div>
          <a
            className="green_btn_a block_btn"
            href="http://t.me/+996990260795"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="btn">
              <p>{t("discuss")}</p>
              <img loading="lazy" className="arrow" src={arrow} alt="" />
            </div>
          </a>
          <img loading="lazy" className="image_absolute1" src={one} alt="" />
          <img loading="lazy" className="image_absolute2" src={two} alt="" />
          <img loading="lazy" className="image_box" src={elips} alt="" />
          <img loading="lazy" className="image_absolute3" src={three} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FirstBlock;
