import {
  FaSearch,
  FaTasks,
  FaPaintBrush,
  FaPenNib,
  FaCogs,
  FaChartLine,
  FaMobileAlt,
  FaGift,
  FaCheckCircle,
  FaTools,
  FaShieldAlt,
  FaDesktop,
  FaDatabase,
  FaRocket,
  FaChartPie,
  FaCreditCard,
  FaBug,
  FaRobot,
  FaChartBar,
  FaHandsHelping,
  FaUser,
  FaCode,
  FaWifi,
  FaPlug,
  FaStore,
  FaDrawPolygon,
  FaGlobe,
  FaUserShield,
  FaChalkboardTeacher,
  FaEnvelope,
} from "react-icons/fa";
import { PiRocketLaunchFill } from "react-icons/pi";
import { HiOutlinePresentationChartLine } from "react-icons/hi2";
import { FaAppStore } from "react-icons/fa";

const main_data = [
  {
    type: "websites",
    data: [
      {
        title: "Сайт визитка",
        description:
          "Продающий сайт под ключ, сделанный на коде c шаблонным или уникальным дизайном и адаптированный под моб устройства",
        points: [
          { text: "Бриф, анализ компании и конкурентов", icon: <FaSearch /> },
          {
            text: "Создание стратегии и воронки продаж",
            icon: <HiOutlinePresentationChartLine />,
          },
          { text: "Шаблонный или уникальный дизайн", icon: <FaPaintBrush /> },
          { text: "Копирайтинг сайта (текст под ключ)", icon: <FaPenNib /> },
          { text: "Базовое SEO", icon: <FaChartLine /> },
          { text: "Адаптация под моб. устройства", icon: <FaMobileAlt /> },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 7 дней",
        price: "от 300$",
      },
      {
        title: "Корпоративный сайт",
        description:
          "Многостраничный сайт под ключ, раскрывающий преимущества компании",
        points: [
          { text: "Бриф, анализ компании и конкурентов", icon: <FaSearch /> },
          {
            text: "Создание стратегии и воронки продаж",
            icon: <HiOutlinePresentationChartLine />,
          },
          {
            text: "Разработка технического задания (Wireframe)",
            icon: <FaTasks />,
          },
          { text: "Шаблонный или уникальный дизайн", icon: <FaPaintBrush /> },
          { text: "Копирайтинг сайта (Текст под ключ)", icon: <FaPenNib /> },
          { text: "Разработка сайта на коде", icon: <FaDesktop /> },
          { text: "Базовое/PRO SEO", icon: <FaChartLine /> },
          { text: "Адаптация под моб. устройства", icon: <FaMobileAlt /> },
          {
            text: "Добавление продающих триггеров на сайте",
            icon: <FaCheckCircle />,
          },
          { text: "Контроль качества от Директора", icon: <FaCheckCircle /> },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 21 дней",
        price: "от 1400$",
      },
      {
        title: "Интернет магазин/каталог",
        description:
          "Интернет каталог или магазин товаров сделанный на WordPress или на коде и адаптированный под моб устройства",
        points: [
          { text: "Создание стратегии, анализ", icon: <FaSearch /> },
          { text: "Шаблонный или уникальный дизайн", icon: <FaPaintBrush /> },
          { text: "Копирайтинг сайта", icon: <FaPenNib /> },
          { text: "Разработка сайта на коде", icon: <FaDesktop /> },
          { text: "Базовое/PRO SEO", icon: <FaChartLine /> },
          { text: "Адаптация под моб.", icon: <FaMobileAlt /> },
          { text: "Оформление и ввод товаров на сайт", icon: <FaTasks /> },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 14 дней",
        price: "от 700$",
      },
      {
        title: "Web-Приложение",
        description:
          "Разработка высокоскоростного и функционального web-приложения с уникальным дизайном, админ панелью, интеграцией с базами данных и API, адаптированного под все устройства.",
        points: [
          {
            text: "Глубокий бизнес анализ и изучение требований",
            icon: <FaSearch />,
          },
          {
            text: "Создание технического задания и архитектуры приложения",
            icon: <FaTasks />,
          },
          { text: "Разработка фронтенда и бэкенда", icon: <FaDesktop /> },
          {
            text: "Интеграция с внешними API и базами данных",
            icon: <FaCogs />,
          },
          { text: "Уникальный и адаптивный дизайн", icon: <FaPaintBrush /> },
          {
            text: "Оптимизация скорости и производительности",
            icon: <FaChartLine />,
          },
          {
            text: "Обеспечение безопасности приложения",
            icon: <FaShieldAlt />,
          },
          {
            text: "Тестирование приложения и устранение багов",
            icon: <FaTools />,
          },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 30 дней",
        price: "от 2000$",
        icon: <FaAppStore />,
      },

      {
        title: "Web-сайт",
        description:
          "Продающий и шустрый сайт под ключ, сделанный на коде, с уникальным дизайном от дизайнеров и адаптированный под моб. устройства",
        points: [
          { text: "Бриф, анализ компании и конкурентов", icon: <FaSearch /> },
          {
            text: "Создание стратегии и воронки продаж",
            icon: <HiOutlinePresentationChartLine />,
          },
          { text: "Разработка тех задания и мокапа сайта", icon: <FaTasks /> },
          { text: "Уникальный дизайн", icon: <FaPaintBrush /> },
          { text: "Копирайтинг сайта (текст под ключ)", icon: <FaPenNib /> },
          { text: "Разработка сайта на коде", icon: <FaDesktop /> },
          { text: "Базовое/PRO SEO", icon: <FaChartLine /> },
          { text: "Адаптация под моб. устройства", icon: <FaMobileAlt /> },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 21 дней",
        price: "от 1000$",
      },
      {
        bool: true,
        title: "Информационная система",
        description:
          "Создание комплекса программ, которые предназначены решать узко специфические задачи клиента",
        points: [
          { text: "Глубокий бизнес анализ", icon: <FaSearch /> },
          { text: "Разработка тех задания", icon: <FaTasks /> },
          { text: "Уникальный дизайн", icon: <FaPaintBrush /> },
          { text: "Копирайтинг сайта", icon: <FaPenNib /> },
          { text: "Разработка системы на коде", icon: <FaCogs /> },
          { text: "PRO SEO", icon: <FaChartLine /> },
          { text: "Тестирование", icon: <FaTools /> },
          { text: "Пилотный проект", icon: <PiRocketLaunchFill /> },
          { text: "Поддержка системы", icon: <FaShieldAlt /> },
          { text: "Контроль качества от Директора", icon: <FaCheckCircle /> },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "В зависимости от проекта",
        price: "по Договору",
      },
    ],
  },
  {
    type: "mobile_apps",
    data: [
      {
        title: "Базовый мобильный проект",
        description:
          "Разработка простого мобильного приложения с базовым функционалом для тестирования идей или минимальных задач. Отличное решение для стартапов на этапе запуска.",
        points: [
          { text: "Создание технического задания", icon: <FaTasks /> },
          { text: "Создание прототипа приложения", icon: <FaDrawPolygon /> },
          {
            text: "Разработка нативного приложения",
            icon: <FaMobileAlt />,
          },
          { text: "Минимальный функционал (MVP)", icon: <FaCode /> },
          { text: "Шаблонный или простой дизайн", icon: <FaPaintBrush /> },
          { text: "Базовая интеграция API", icon: <FaPlug /> },
          { text: "Тестирование и минимальная поддержка", icon: <FaTools /> },
          { text: "Публикация в магазинах приложений", icon: <FaStore /> },
        ],
        timeline: "от 30 дней",
        price: "от 3000$",
      },
      {
        title: "Стандартное мобильное приложение",
        description:
          "Средний проект для бизнеса с расширенным функционалом, интеграцией базы данных и кастомным дизайном. Оптимальный выбор для эффективного взаимодействия с клиентами.",
        points: [
          { text: "Создание технического задания", icon: <FaTasks /> },
          { text: "Проектирование архитектуры приложения", icon: <FaTasks /> },
          { text: "Кастомный UX/UI дизайн", icon: <FaPaintBrush /> },
          {
            text: "Разработка нативного приложения",
            icon: <FaMobileAlt />,
          },
          { text: "Интеграция с базой данных", icon: <FaDatabase /> },
          {
            text: "Поддержка нескольких ролей пользователей",
            icon: <FaUser />,
          },
          { text: "Интеграция внешних API и сервисов", icon: <FaCogs /> },
          { text: "Интеграция платёжных систем", icon: <FaCreditCard /> },
          { text: "Оптимизация скорости и стабильности", icon: <FaRocket /> },
          { text: "Обеспечение безопасности данных", icon: <FaShieldAlt /> },
          { text: "Тестирование на разных устройствах", icon: <FaBug /> },
          {
            text: "Отладка и оптимизация производительности",
            icon: <FaTools />,
          },
          { text: "Публикация в App Store и Google Play", icon: <FaStore /> },
        ],
        timeline: "от 60 дней",
        price: "от 8000$",
      },
      {
        bool: true,
        title: "Премиум мобильное приложение",
        description:
          "Сложное приложение с индивидуальной архитектурой, аналитикой, интеграциями с внешними сервисами и поддержкой высокой нагрузки. Решение для крупных бизнесов и корпораций.",
        points: [
          { text: "Создание технического задания", icon: <FaTasks /> },
          { text: "Разработка уникальной архитектуры", icon: <FaTasks /> },
          {
            text: "Продвинутый дизайн и пользовательский опыт",
            icon: <FaPaintBrush />,
          },
          {
            text: "Разработка нативного приложения",
            icon: <FaMobileAlt />,
          },
          {
            text: "Сложная логика и автоматизация процессов",
            icon: <FaRobot />,
          },
          {
            text: "Интеграция с аналитическими системами",
            icon: <FaChartBar />,
          },
          { text: "Поддержка оффлайн-режима", icon: <FaWifi /> },
          { text: "Интеграция API и внешних сервисов", icon: <FaPlug /> },
          { text: "Интеграция платёжных систем", icon: <FaCreditCard /> },
          { text: "Оптимизация скорости и стабильности", icon: <FaRocket /> },
          { text: "Обеспечение безопасности данных", icon: <FaShieldAlt /> },
          { text: "Оптимизация под высокие нагрузки", icon: <FaRocket /> },
          { text: "Тестирование на разных устройствах", icon: <FaBug /> },
          {
            text: "Отладка и оптимизация производительности",
            icon: <FaTools />,
          },
          { text: "Публикация в App Store и Google Play", icon: <FaStore /> },
          {
            text: "Поддержка и регулярные обновления",
            icon: <FaHandsHelping />,
          },
          {
            text: "Тестирование и поддержка безопасности",
            icon: <FaShieldAlt />,
          },
        ],
        timeline: "от 90 дней",
        price: "от 12000$",
      },
    ],
  },
  {
    type: "crm",
    data: [
      // {
      //   title: "CRM-система и автоматизация бизнес-процессов",
      //   description:
      //     "Разработка и внедрение CRM-систем, которые помогут вашему бизнесу эффективно управлять клиентской базой, автоматизировать ключевые процессы и повысить общую производительность. Полная интеграция с существующими системами и индивидуальный подход для достижения лучших результатов.",
      //   points: [
      //     {
      //       text: "Анализ и консультации по бизнес-процессам",
      //       icon: <FaSearch />,
      //     },
      //     { text: "Проектирование архитектуры CRM", icon: <FaTasks /> },
      //     { text: "Разработка пользовательских решений", icon: <FaCogs /> },
      //     {
      //       text: "Интеграция с внешними системами и базами данных",
      //       icon: <FaDatabase />,
      //     },
      //     { text: "Автоматизация рабочих процессов", icon: <FaRobot /> },
      //     { text: "Дизайн и UX/UI интерфейса", icon: <FaPaintBrush /> },
      //     { text: "Настройка аналитики и отчетов", icon: <FaChartBar /> },
      //     {
      //       text: "Интеграция с почтой и мессенджерами",
      //       icon: <FaEnvelope />,
      //     },
      //     { text: "Обучение сотрудников", icon: <FaChalkboardTeacher /> },
      //     {
      //       text: "Поддержка и регулярные обновления",
      //       icon: <FaHandsHelping />,
      //     },
      //     {
      //       text: "Домен и хостинг в подарок*",
      //       icon: <FaGift color="gold" />,
      //     },
      //   ],
      //   timeline: "от 60 дней",
      //   price: "от 5000$",
      // },
      {
        title: "Базовая CRM-система",
        description:
          "Простое решение для небольших компаний, позволяющее управлять клиентами, отслеживать продажи и автоматизировать базовые процессы. Отлично подходит для стартапов и малого бизнеса.",
        points: [
          {
            text: "Анализ ключевых процессов компании",
            icon: <FaSearch />,
          },
          { text: "Разработка базового функционала", icon: <FaTasks /> },
          { text: "Интеграция с электронной почтой", icon: <FaEnvelope /> },
          {
            text: "Шаблонный или минималистичный дизайн",
            icon: <FaPaintBrush />,
          },
          { text: "Настройка базовых отчетов", icon: <FaChartLine /> },
          {
            text: "Обучение основам использования",
            icon: <FaChalkboardTeacher />,
          },
          {
            text: "Домен и хостинг в подарок*",
            icon: <FaGift color="gold" />,
          },
        ],
        timeline: "от 30 дней",
        price: "от 3000$",
      },
      {
        title: "Стандартная CRM-система",
        description:
          "Решение среднего уровня с поддержкой роли пользователей, интеграцией баз данных и расширенным функционалом для автоматизации бизнеса. Подходит для растущих компаний.",
        points: [
          { text: "Проектирование структуры данных", icon: <FaTasks /> },
          { text: "Кастомизация под нужды бизнеса", icon: <FaCogs /> },
          { text: "Интеграция с базами данных", icon: <FaDatabase /> },
          { text: "Поддержка ролей пользователей", icon: <FaUserShield /> },
          { text: "Расширенная аналитика", icon: <FaChartPie /> },
          {
            text: "Интеграция с API и внешними системами",
            icon: <FaPlug />,
          },
          { text: "Обучение и внедрение", icon: <FaChalkboardTeacher /> },
          {
            text: "Домен и хостинг в подарок*",
            icon: <FaGift color="gold" />,
          },
        ],
        timeline: "от 45 дней",
        price: "от 7000$",
      },
      {
        bool: true,
        title: "Премиум CRM-система",
        description:
          "Полноценная CRM-система с индивидуальной архитектурой, сложной автоматизацией, интеграцией с аналитикой и высокой производительностью. Идеально для крупных предприятий и корпораций.",
        points: [
          { text: "Полный бизнес-анализ процессов", icon: <FaSearch /> },
          {
            text: "Индивидуальная разработка архитектуры",
            icon: <FaTasks />,
          },
          {
            text: "Поддержка сложной логики и автоматизации",
            icon: <FaRobot />,
          },
          {
            text: "Интеграция с аналитическими системами",
            icon: <FaChartBar />,
          },
          {
            text: "Интеграция с API, ERP и другими системами",
            icon: <FaPlug />,
          },
          { text: "Настройка многоязычного интерфейса", icon: <FaGlobe /> },
          { text: "Оптимизация под высокую нагрузку", icon: <FaRocket /> },
          {
            text: "Тестирование безопасности и стабильности",
            icon: <FaShieldAlt />,
          },
          {
            text: "Постоянная поддержка и обновления",
            icon: <FaHandsHelping />,
          },
          {
            text: "Домен и хостинг в подарок*",
            icon: <FaGift color="gold" />,
          },
        ],
        timeline: "от 90 дней",
        price: "от 15000$",
      },
    ],
  },
  {
    type: "it_platforms",
    data: [
      {
        title: "Базовая платформа",
        description:
          "Создание минимально жизнеспособного продукта (MVP), позволяющего протестировать вашу бизнес-идею с базовым функционалом и упрощённым дизайном.",
        points: [
          { text: "Регистрация и авторизация пользователей", icon: <FaUser /> },
          { text: "Базовые функции и минимальная логика", icon: <FaCode /> },
          {
            text: "Шаблонный или минималистичный дизайн",
            icon: <FaPaintBrush />,
          },
          { text: "Базовая интеграция с внешними сервисами", icon: <FaCogs /> },
          {
            text: "SEO-оптимизация для начала продвижения",
            icon: <FaChartLine />,
          },
          { text: "Хостинг и домен в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 30 дней",
        price: "от 1500$",
      },
      {
        title: "Стандартная платформа",
        description:
          "Разработка среднего уровня проекта с полной интеграцией базы данных, улучшенной логикой и поддержкой пользовательских ролей.",
        points: [
          { text: "Продвинутая логика и бизнес-функционал", icon: <FaTasks /> },
          {
            text: "Интеграция с реляционной базой данных",
            icon: <FaDatabase />,
          },
          { text: "Разработка UX/UI дизайна", icon: <FaPaintBrush /> },
          { text: "Оптимизация скорости загрузки", icon: <FaRocket /> },
          { text: "Базовая аналитика и отчётность", icon: <FaChartPie /> },
          { text: "Интеграция платёжных систем", icon: <FaCreditCard /> },
          { text: "Тестирование и устранение багов", icon: <FaBug /> },
          { text: "Интеграция с внешними системами", icon: <FaCogs /> },
          { text: "Масштабируемость и безопасность", icon: <FaShieldAlt /> },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 45 дней",
        price: "от 3500$",
      },
      {
        bool: true,
        title: "Премиум платформа",
        description:
          "Создание сложной платформы с уникальной архитектурой, продвинутой аналитикой, интеграциями с внешними сервисами и оптимизацией под высокую нагрузку.",
        points: [
          { text: "Разработка индивидуальной архитектуры", icon: <FaTasks /> },
          {
            text: "Высокая производительность и масштабируемость",
            icon: <FaDesktop />,
          },
          { text: "Интеграции с API и внешними сервисами", icon: <FaCogs /> },
          {
            text: "Разработка уникального UX/UI дизайна",
            icon: <FaPaintBrush />,
          },
          {
            text: "Сложная логика и автоматизация процессов",
            icon: <FaRobot />,
          },
          { text: "Аналитика и визуализация данных", icon: <FaChartBar /> },
          {
            text: "Защита данных и безопасность платформы",
            icon: <FaShieldAlt />,
          },
          { text: "Поддержка и обучение команды", icon: <FaTools /> },
          {
            text: "Техническое сопровождение после запуска",
            icon: <FaHandsHelping />,
          },
          { text: "Домен и хостинг в подарок*", icon: <FaGift color="gold" /> },
        ],
        timeline: "от 60 дней",
        price: "от 8000$",
      },
    ],
  },
];

export default main_data;
