import Advantages from "./components/advantages/advantages";
import FirstBlock from "./components/first_block/first_block";
import Footer from "./components/footer/footer";
import FormBlock from "./components/form_block/form_block";
import Header from "./components/header/header";
import Programs from "./components/programs/programs";
import Projects from "./components/projects/projects";
import Servicies from "./components/servicies/servicies";
import Stages from "./components/stages/stages";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSlowScroll from "./UI/scroll/scroll";
import Aos from "aos";
import { useEffect, useState } from "react";
import Loader from "./UI/loader/Loader";
import Tariffs from "./components/tariffs/tariffs";

function App() {
  Aos.init();
  useSlowScroll(7);
  const [loading, setLoading] = useState(false);

  const loadData = async (lang) => {
    setLoading(true);
    if (lang) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    } else {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <Header loadData={loadData} />
      <FirstBlock />
      <div className="container">
        <Servicies />
      </div>
      <Tariffs />
      <div className="container">
        <Advantages />
        <Projects />
      </div>
      <Programs />
      <div className="container">
        <Stages />
      </div>
      <FormBlock />
      <Footer />
    </div>
  );
}

export default App;
