import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ru: {
    translation: {
      leng: "ru",
      services: "Услуги",
      projects: "Проекты",
      programs: "Программы",
      stages: "Этапы",
      write: "Связаться с нами",
      first_text:
        "Мы — команда виртуозных разработчиков, создающих уникальные веб-сайты и мобильные приложения, чтобы выделить ваш бизнес.",
      discuss: "Обсудите с нами",
      quality: "Непревзойденное качество",
      quality_text:
        "Мы — команда креативных мастеров, создающих уникальные цифровые решения. Наши проекты определяют стандарты качества и инноваций в IT.",
      speed: "Молниеносная скорость",
      speed_text:
        "Мы разрабатываем быстрые и надежные решения, обеспечивающие максимальную производительность вашего бизнеса без задержек.",
      design: "Прекрасный дизайн",
      design_text:
        "Наши дизайнеры создают визуальные шедевры, которые привлекают внимание и превращают пользователей в постоянных клиентов.",
      inspiring: "Вдохновляющий дизайн",
      inspiring_text:
        "Мы создаем дизайн, который не только впечатляет, но и подчеркивает уникальность вашего бренда.",
      sophisticated: "Изысканная разработка",
      sophisticated_text:
        "Пишем код с максимальной тщательностью, обеспечивая стабильность и высокую производительность.",
      thorough: "Тщательное тестирование",
      thorough_text:
        "Проверяем каждый элемент, чтобы ваш продукт работал безупречно.",
      continuous: "Постоянная поддержка",
      continuous_text:
        "Оказываем поддержку и развиваем ваш проект для роста и адаптации к новым вызовам.",
      name: "Введите ваше Имя",
      about_project: "Вкратце расскажите о вашем проекте",
      rights: "Все права защищены",
      footer_text:
        "Мы — команда, создающая цифровые решения, которые трансформируют ваш бизнес. Наши проекты отличаются высоким качеством, инновационным подходом и вниманием к деталям.",
      social: "Мы в соцсетях:",
      secend_text:
        "Наш подход к работе – это не просто выполнение заданий, а искусство создания эффективных решений",
    },
  },
  en: {
    translation: {
      leng: "en",
      services: "Services",
      projects: "Projects",
      programs: "Programs",
      stages: "Stages",
      write: "Contact Us ",
      first_text:
        "We are a team of virtuoso developers creating unique websites and mobile apps to make your business stand out.",
      discuss: "Discuss with us",
      quality: "Unmatched Quality",
      quality_text:
        "We are a team of creative masters creating unique digital solutions. Our projects set the standards for quality and innovation in IT.",
      speed: "Lightning Speed",
      speed_text:
        "We develop incredibly fast and reliable solutions that help your business operate without delays and with maximum efficiency.",
      design: "Beautiful Design",
      design_text:
        "Our designers create visual masterpieces that not only attract attention but also turn users into your loyal customers.",
      inspiring: "Inspiring Design",
      inspiring_text:
        "We create designs that are not only stunning but also highlight your brand's uniqueness.",
      sophisticated: "Sophisticated Development",
      sophisticated_text:
        "Every line of code is crafted with precision to ensure stability and top performance.",
      thorough: "Thorough Testing",
      thorough_text: "We examine every aspect to ensure flawless operation.",
      continuous: "Continuous Support",
      continuous_text:
        "We provide ongoing support and evolve your project to help your business grow and adapt.",
      name: "Enter your name",
      about_project: "Briefly tell us about your project",
      rights: "All rights reserved",
      footer_text:
        "We are a team that crafts digital solutions that transform your business. Our projects stand out for their quality, innovation, and meticulous attention to detail.",
      social: "We are on social media:",
      secend_text:
        "Our approach to work is not just about completing tasks, but about the art of creating effective solutions.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ru",
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
